import React, { useContext, useEffect, useState } from "react";
import { AuthState } from "../services/Context";
import UserService from "../services/user.service";
import Loading from "./Loading";

function Stats() {
  const { userLoginState } = useContext(AuthState);
  const [savesData, setSavesData] = useState([]);
  const [loading, setLoading] = useState();
  const token = localStorage.getItem("login_token");

  useEffect(() => {
    if (userLoginState) {
      setLoading(true);
      UserService.getSaves(token)

        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then((data) => {
          setSavesData(data.results[0]);
        });
    }
  }, [token, userLoginState]);

  console.log(savesData);
  console.log(loading);

  // from 2023-01-01
  const totSaved = savesData.reduce((acc, obj) => {
    return acc + Number(obj.amount);
  }, 0);
  

    // last 7 days

  const weekData = savesData.slice(-7);
  console.log(savesData);

  const totSavesWeek = weekData.reduce((acc, obj) => {
    return acc + Number(obj.amount);
  }, 0);
  // const avgSavesWeek = Math.round(totSavesWeek / weekData.length);

  
  const checkStatsExists =
  loading ? 
  <><div>Loading...</div>
  <Loading/></> : (
    savesData.length === 0 ? (
      <>
        <div>No statistics yet </div>
      </>
    ) : (
      <h1>Statistics</h1>
    ))

  return (
    <>
      <div className="stats-wrapper">
      <div className="stats-title">{checkStatsExists}</div>
        <div className="stats-wrapp stats-total">
          <div>
            <h1>Since 2023-09-01:</h1>
          </div>
          <div>Total:</div> <span>{totSaved} EUR</span>
          </div>
        <div className="stats-wrapp stats-week">
          <div>
            <h1>Last 7 days:</h1>
          </div>
          <div>Total:</div> <span>{totSavesWeek} EUR</span>
          
        </div>
      </div>
    </>
  );
}

export default Stats;
