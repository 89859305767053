import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthState } from "../services/Context";

function Logout() {
  const { userLoginState, setUserLoginState } = useContext(AuthState);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    setUserLoginState(false);
  };

  const handleNoLogout = () => {
    navigate("/", { replace: true })
  };

  return (
    <>
      {userLoginState ? (
        <div className="navigation">
          
          <div className="nav-links-logout">
            <h1>Are you sure you want to logout?</h1>
            
              <button className="logout-btn" onClick={handleLogout}>
              YES
              </button>
              <button className="logout-btn" onClick={handleNoLogout}>
              NO
              </button>
            
          </div>
        </div>
      ) : (
        <div className="navigation">
          
          <div className="nav-links-logout">
            <h1>Are you not logged in.</h1>
            
                          
          </div>
        </div>
      )}
    </>
  );
}

export default Logout;
