import React from "react";

import H2gallery from "./H2gallery";


function KawaH2() {
  return (
    <div className="kawa-wrapper">
      <div className="table-section">
      <div className="table-section-name"><h2>Engine</h2></div>
      
      <table>
      <tbody>
      
      <tr>
        <th>Engine Type</th>
        <td>Liquid-cooled, 4-stroke, in-line four with Supercharger</td>
      </tr>


      <tr>
        <th>Compression ratio</th>
        <td>11.2:1</td>
      </tr>

      <tr>
        <th>Valve system</th>
        <td>DOHC, 16 valves</td>
      </tr>

      <tr>
        <th>Bore x stroke</th>
        <td>76.0 x 55.0 mm</td>
      </tr>

      <tr>
        <th>Displacement</th>
        <td>998 cm³</td>
      </tr>

      <tr>
        <th>Fuel System</th>
        <td>Fuel injection with dual injection: 40 mm x 4</td>
      </tr>

      <tr>
        <th>Lubrication</th>
        <td>Petrol mix</td>
      </tr>

      <tr>
        <th>Starting system</th>
        <td>Electric</td>
      </tr>

      <tr>
        <th>Ignition</th>
        <td>Digital</td>
      </tr>
      </tbody>
      </table>
      <div className="table-section-name"><h2>Performance & Transmission</h2></div>
      
      <table>
      <tbody>
      <tr>
        <th>Maximum power</th>
        <td>147.1 kW (200 PS) / 11,000 rpm</td>
      </tr>

      <tr>
        <th>Maximum power with RAM Air</th>
        <td>154.1 kW (210 PS) / 11,000 rpm</td>
      </tr>

      <tr>
        <th>Maximum torque</th>
        <td>137.3 N•m (14.0 kgf•m) / 8,500 rpm</td>
      </tr>

      <tr>
        <th>CO2 emission</th>
        <td>128 g/km</td>
      </tr>

      <tr>
        <th>Fuel consumption</th>
        <td>5.4 l/100km</td>
      </tr>

      <tr>
        <th>Transmission</th>
        <td>6-speed, return shift</td>
      </tr>

      <tr>
        <th>Clutch</th>
        <td>Wet, multi disc</td>
      </tr>

      <tr>
        <th>Final drive</th>
        <td>Sealed chain</td>
      </tr>
      </tbody>
      </table>
      <div className="table-section-name"><h2>Frame & Dimensions</h2></div>
      
      <table>
      <tbody>
      <tr>
        <th>Frame type</th>
        <td>Trellis, high-tensile steel with swingarm mounting plate</td>
      </tr>

      <tr>
        <th>Trail</th>
        <td>103 mm</td>
      </tr>

      <tr>
        <th>Wheel travel front</th>
        <td>120 mm</td>
      </tr>

      <tr>
        <th>Wheel travel rear</th>
        <td>139 mm</td>
      </tr>

      <tr>
        <th>Tyre, front</th>
        <td>120/70 ZR17 M/C (58W)</td>
      </tr>

      <tr>
        <th>Tyre, rear</th>
        <td>190/55 ZR17 M/C (75W)</td>
      </tr>

      <tr>
        <th>L x W x H</th>
        <td>2,175 x 790 x 1,260 mm</td>
      </tr>

      <tr>
        <th>Steering angle L R</th>
        <td>30° / 30°</td>
      </tr>

      <tr>
        <th>Wheel base</th>
        <td>1,480 mm</td>
      </tr>

      <tr>
        <th>Ground clearance</th>
        <td>130 mm</td>
      </tr>

      <tr>
        <th>Fuel capacity</th>
        <td>19.0 litres</td>
      </tr>

      <tr>
        <th>Seat height</th>
        <td>835 mm</td>
      </tr>

      <tr>
        <th>Curb mass</th>
        <td>267 kg</td>
      </tr>
      </tbody>
      </table>
      <div className="table-section-name"><h2>Brakes & Suspension</h2></div>
      
      <table>
      <tbody>
      <tr>
        <th>Front brake type</th>
        <td>Dual semi-floating discs</td>
      </tr>

      <tr>
        <th>Front brake diameter</th>
        <td>Ø 320 mm</td>
      </tr>

      <tr>
        <th>Front brake caliper type</th>
        <td>Brembo radial-mount, Stylema monobloc, opposed 4-piston</td>
      </tr>

      <tr>
        <th>Rear brake type</th>
        <td>Single disc</td>
      </tr>

      <tr>
        <th>Rear brake diameter</th>
        <td>Ø 250 mm</td>
      </tr>

      <tr>
        <th>Rear brake caliper type</th>
        <td>Dual-piston</td>
      </tr>

      <tr>
        <th>Front suspension type</th>
        <td>Inverted fork with KECS-controlled compression and rebound damping, manual spring preload adjustability, and top-out springs</td>
      </tr>

      <tr>
        <th>Front suspension diameter</th>
        <td>Ø 43 mm</td>
      </tr>

      <tr>
        <th>Rear suspension type</th>
        <td>Uni-Trak, BFRC lite gas-charged shock with piggyback reservoir, KECS-controlled compression and rebound damping, and remote spring preload adjustability</td>
      </tr>
      </tbody>
      </table>
      
    </div>
    <H2gallery/>
      </div>
      
  );
}

export default KawaH2;