import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthState } from "../services/Context";
// import logoutbtn from "../media/logout-btn.jpg"

function Navbar() {
  const { userLoginState } = useContext(AuthState);

  // const handleLogout = () => {
  //   localStorage.clear();
  //   setUserLoginState(false);
  // };

  return (
    <>
      {userLoginState ? (
        <div className="navigation">
          <div className="nav-links">
            <Link className="nav-home" to="/">
              Home
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-save" to="/h2">
              Saves
            </Link>
          </div>
          {/* <div className="nav-links">
            <Link className="nav-stats" to="/stats">
              Statistics
            </Link>
          </div> */}
          <div className="nav-links">
            <Link className="nav-add" to="/add">
              Add data
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-logout" to="/logout">
              Logout
            </Link>
          </div>
        </div>
      ) : (
        <div className="navigation">
          <div className="nav-links">
            <Link className="nav-home" to="/">
              Home
            </Link>
          </div>
          {/* <div className="nav-links">
            <Link className="nav-register" to="/register">
              Registration
            </Link>
          </div> */}
          <div className="nav-links">
            <Link className="nav-login" to="/login">
              Login
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
