import React, { useRef, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthState } from "../services/Context";
import AuthService from "../services/auth.service";
import Loading from "./Loading";

function Login() {
  const { setUserLoginState } = useContext(AuthState);
  const [loading, setLoading] = useState();
  const userEmailRef = useRef();
  const userPasswordRef = useRef();
  const navigate = useNavigate();

  function handleLoginSubmit(event) {
    event.preventDefault();

    const userLogin = {
      email: userEmailRef.current.value,
      password: userPasswordRef.current.value,
    };

    if (!userEmailRef.current.value || !userPasswordRef.current.value) {
      alert("Fill in all fields");
    } else {
      setLoading(true);

      AuthService.signIn(userLogin)
        .then((response) => response.json())
        .then(function (data) {
          if (data.status === "authenticated") {
            AuthService.setToken(data.token, data.dataToken);
            setUserLoginState(true);
            navigate("/", { replace: true });
          } else if (data.error === "Incorrect email or password") {
            console.log("No success");
            alert("Incorrect email or password. Please try again");
          }
        })
        .catch(console.error);
    }
    document.getElementById("login-form").reset();
  }

  const checkIfSuccess = loading ? (
    <>
      <div>loading....</div>
      <Loading />
    </>
  ) : (
    <div></div>
  );

  return (
    <>
      <div className="login-wrapper">
        <div className="user-login-title">Log in:</div>
        <div>{checkIfSuccess}</div>
        <div className="login-form-wrapper">
          <form id="login-form">
            <label htmlFor="user-email">
              Email<span className="red">*</span>
            </label>
            <input
              ref={userEmailRef}
              type="email"
              id="user-email"
              name="user-email"
            />

            <label htmlFor="user-password">
              Password<span className="red">*</span>
            </label>
            <input
              ref={userPasswordRef}
              type="password"
              id="user-password"
              name="user-password"
            />
            <div className="required">* required</div>
            <button
              className="form-submit-btn"
              onClick={handleLoginSubmit}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
