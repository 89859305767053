import React, { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
import UserService from "../services/user.service";
import { AuthState } from "../services/Context";
// import { useEffect } from "react";

function AddTask() {
  const token = localStorage.getItem("login_token");
  // const [saveDate, setSaveDate] = useState('');
  const [saveComment, setSaveComment] = useState('');
  const [saveAmount, setSaveAmount] = useState('');
  
  const { userLoginState, setSubmitState } = useContext(AuthState);

  // const handleChangeDate = (e) => {
  //   setSaveDate(e.target.value)
  // }
  const handleChangeAmount = (e) => {
    setSaveAmount(e.target.value)
  }
  const handleChangeComment = (e) => {
    setSaveComment(e.target.value)
  }
  

  // console.log('date',saveDate)
  console.log('comment',saveComment)
  console.log('amount', saveAmount)
    
// useEffect(()=>{
//   navigate("/tasks", { replace: true });
// },[formSubmit, navigate])

  const handleSubmit = (event) => {
    event.preventDefault();
    const newSave = {
      // date_save: new Date(saveDate),
      comment: saveComment,
      amount: Number(saveAmount),
      archived: 0,
    };

    console.log(newSave);

    if (
      // !saveDate ||
      !saveComment ||
      !saveAmount 
    ) {
      alert("Fill in all fields");
    } else {
      UserService.saveAdd(token, newSave).then(async function (response) {
        await response.json();
        setSubmitState(true);
        console.log(response);
        document.getElementById("add-save-form").reset();
        return
      });
    }
    
    // navigate("/tasks", { replace: true });
    // setFormSubmit(true);
  };

  return (
    <>
      {userLoginState ? (
        <div className="add-save">
          <div className="wrapper-title">
            <h1>Add new record:</h1>
          </div>

          <div className="add-save-form-wrapper">
            <form id="add-save-form">
            {/* <label htmlFor="task-date">
                Date:<span className="red">*</span>
              </label>
              <input
                value={saveDate}
                type="date"
                id="inp-date"
                name="inp-date"
                onChange={handleChangeDate}
              /> */}
              <label htmlFor="task-amount">
                Amount EUR:<span className="red">*</span>
              </label>
              <input
                value={saveAmount}
                type="number"
                id="inp-amount"
                name="inp-amount"
                onChange={handleChangeAmount}
              />

              <label htmlFor="save-comment">
                Comment:<span className="red">*</span>
              </label>
              <input
                value={saveComment}
                // type="text"
                id="inp-comment"
                name="inp-comment"
                onChange={handleChangeComment}
              />

              
              <div className="required">* required</div>
              <div>
                <button
                  className="form-submit-btn"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="error">
          <h1>You are not authorized to access this page</h1>
        </div>
      )}
    </>
  );
}

export default AddTask;
