import { SAVES_URL } from "./utils";


const getSaves = (token) => {
  return fetch(SAVES_URL + "h2", {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const getTarget = (token) => {
  return fetch(SAVES_URL + "h2", {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const  saveAdd = (token, data) => {
  return fetch(SAVES_URL + "addsave", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const saveDelete = (token, data) => {
  return fetch(SAVES_URL + "delete", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const UserService = {
  getSaves,
  getTarget,
  saveAdd,
  saveDelete,
};

export default UserService;
