import h21 from "../media/h2_1.png"
import h22 from "../media/h2_2.png"
import h23 from "../media/h2_3.png"
import h24 from "../media/h2_4.jpeg"
import h25 from "../media/h2_5.png"
import h26 from "../media/h2_6.png"
import h27 from "../media/h2_7.png"
import h28 from "../media/h2_8.png"
import h29 from "../media/h2_9.png"
import h210 from "../media/h2_10.png"
import h211 from "../media/h2_11.png"
import h21t from "../media/h2_1t.png"
import h22t from "../media/h2_2t.png"
import h23t from "../media/h2_3t.png"
import h24t from "../media/h2_4t.jpg"
import h25t from "../media/h2_5t.png"
import h26t from "../media/h2_6t.png"
import h27t from "../media/h2_7t.png"
import h28t from "../media/h2_8t.png"
import h29t from "../media/h2_9t.png"
import h210t from "../media/h2_10t.png"
import h211t from "../media/h2_11t.png"
import React from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the CSS

const images = [
  {
    original: h21,
    thumbnail: h21t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h22,
    thumbnail: h22t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h23,
    thumbnail: h23t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h24,
    thumbnail: h24t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h25,
    thumbnail: h25t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h26,
    thumbnail: h26t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h27,
    thumbnail: h27t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h28,
    thumbnail: h28t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h29,
    thumbnail: h29t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h210,
    thumbnail: h210t,
    description: 'Kawasaki H2 SX SE',
  },
  {
    original: h211,
    thumbnail: h211t,
    description: 'Kawasaki H2 SX SE',
  }



  // Add more images as needed
];

function H2gallery() {
  return (
    <div className="image-gallery-container">
      <Gallery items={images} />
    </div>
  );
};

export default H2gallery;
