import React, { useContext} from "react";
import { Link } from "react-router-dom";
// import gift2Logo from "../media/gift-new2.png";
import { AuthState } from "../services/Context";
import KawaH2 from "./KawaH2";

function Home() {
  const { userLoginState } = useContext(AuthState);
  const dataToken = JSON.parse(localStorage.getItem("data_token"));
  
  

  
  return (
    <div className="home-wrapper">
      <div className="wrapper-title">
        
        

      </div>
      {/* <img className="img-medium" alt="gift" src={gift2Logo} /> */}
      
      <div>
        {userLoginState ? (
          <>
            <div className="home-div">
              <div className="welcome-user"><span>{dataToken.name}</span>, welcome back.</div>
              <h1>Kawasaki H2 SX SE</h1> <h1>MY2024</h1>
              <KawaH2/>
              {/* <Link to="/profile">profile</Link> */}
            </div>
          </>
        ) : (
          <div>
            <>Only for authorized users.
            Please </>  
            <Link to="/login">login</Link>.
          </div>
        )}
      </div>{" "}
    </div>
  );
}

export default Home;
