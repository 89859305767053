import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthState } from "../services/Context";
import UserService from "../services/user.service";
import AddSave from "./AddSave";
import Loading from "./Loading";

function Saves() {
  const { userLoginState, submitState } = useContext(AuthState);
  const [savesData, setSavesData] = useState([]);
  // const [targetData, setTargetData] = useState([]);
  // const [delState, setDelState] = useState(false);
  const [loading, setLoading] = useState();
  const [showForm, setShowForm] = useState(false);
  const [showFormData, setShowFormData] = useState(false);
  const token = localStorage.getItem("login_token");

  

  useEffect(() => {
    if (userLoginState) {
      setLoading(true);
      UserService.getSaves(token)
        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then((data) => {
          setSavesData(data.results[0]);
          console.log(data.results[0])
        })
      
    }
  }, [token, userLoginState, submitState]);

  const totalSavedAmount = savesData.reduce((acc, obj) => {
    return acc + obj.amount;
  }, 0)
  console.log(savesData);
  console.log(totalSavedAmount);

  const remainingSave = 26200 - totalSavedAmount;
  const savedPercent = ((totalSavedAmount / 26200)*100).toFixed(1);
  console.log(savedPercent);
  
  const showFormClick = () => {
    setShowForm(!showForm);
  }

  const showFormClickData = () => {
    setShowFormData(!showFormData);
  }
  
  const checkSaveExists = loading ? (
    <><div>Loading...</div>
    <Loading/></>
  ) : savesData.length === 0 ? (
    <>
      <div>
        You don't have any records yet. You can add one{" "}
        <Link to="/addsave">here.</Link>
      </div>
    </>
  ) : (
    <div className="saves-target">
      <h1><span>Target:</span> 26200 €</h1>
      <h1><span>Saved:</span> {totalSavedAmount} €</h1>
      <h1><span>Remaining:</span> {remainingSave} €</h1>
      <h1><span>Already saved:</span> {savedPercent} %</h1>
    </div>
  );

  return (
    <>
      {userLoginState ? (
        <>
 <div className="saves-title">{checkSaveExists}</div>

        <button className="btn-add-hide" onClick={showFormClick}>{showForm ? "Hide" : "Add record"}</button>
        {showForm && (
        
          <AddSave/>
        
      )}
      <button className="btn-add-hide" onClick={showFormClickData}>{showFormData ? "Hide" : "Show details"}</button>
        {showFormData && (
        
          
        <div className="saves-wrapper">
         
          <div className="saves-container">
            {savesData.map((save, index) => (
              <div className="single-save-container" key={save.id}>
                {/* <div className="tasks-image-wrap">
                  <img
                    className="gift-logo"
                    src={giftLogo}
                    alt="gift logo"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </div> */}
                <div className="save-info-wrap">
                  <div className="save-single-data-title">
                    <div className="single-save-date-value">
                      {new Date(save.date_save).toLocaleDateString('lt-LT')}
                    </div>
                  </div>
                  <div className="save-single-data">
                    <div className="single-save-amount">Saved amount:</div>
                    <div
                      className={
                        save.amount >= 1
                          ? "single-save-green-value"
                          : "single-save-red-value"
                      }
                    >
                      {Math.round(((save.amount) + Number.EPSILON)*100)/100} €
                    </div>
                  </div>
                  <div className="save-single-data">
                    <div className="single-save-comment">
                      Comment:
                    </div>
                    <div className="single-save-comment-value">
                      {save.comment}
                    </div>
                  </div>
                  
                  
                  {/* <div className="single-task-delete">
                    <button
                      id={index}
                      onClick={() =>
                        deleteSave(save.id, save.date_save)
                      }
                    >
                      Delete record
                    </button>
                  </div>
                  <div className="single-task-update">
                    <button
                      id={index}
                      onClick={() =>
                        deleteSave(save.id, save.date_save)
                      }
                    >
                      Edit record
                    </button>
                  </div> */}
                </div>

                <div></div>
              </div>
            ))}
          </div>
        </div>
      )}
        
        
        </>
      ) : (
        <div className="error">
          <h1>You are not authorized to access this page</h1>
        </div>
      )}
      
    </>
    
  );
}

export default Saves;
