import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";

function Welcome() {
  const [confCode, setConfCode] = useState();
  const [accConf, setAccConf] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const confirmationCode = () => setConfCode(queryParameters.get("code"));

    AuthService.confirmUser(queryParameters.get("code"))

      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if(data.modifiedCount === 1){
          setAccConf(true)
        }
        console.log(confirmationCode)
      });
  }, [confCode]);

  return (
    <div className="home-wrapper">
      {accConf ? (<div>You confirmed your email, now you can login.</div>):(<div>Something went wrong. Please contact system administrator.</div>)}
      
    </div>
  );
}

export default Welcome;
