import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./components/Home";
import KawaH2 from "./components/KawaH2";
// import Register from "./components/Register";
import Login from "./components/Login";
import Error from "./services/Error";
import Footer from "./components/Footer";
import Saves from "./components/Saves";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import { AuthState } from "./services/Context";
import Stats from "./components/Stats";
import AddSave from "./components/AddSave";
import Register from "./components/Register";
import Welcome from "./components/Welcome";
import Logout from "./components/Logout";

function App() {
  const [userLoginState, setUserLoginState] = useState(false);
  const [submitState, setSubmitState] = useState(false);

  const token = localStorage.getItem("login_token");

  useEffect(() => {
    if (!token) {
      setUserLoginState(false);
    } else {
      setUserLoginState(true);
    }
  }, [token]);

  return (
    <div className="App">
      <div>
        <main>
          <AuthState.Provider value={{ userLoginState, setUserLoginState, submitState, setSubmitState }}>
            <Header />
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/kawah2" element={<KawaH2 />} />
              <Route path="/add" element={<AddSave />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/h2" element={<Saves />} />
              <Route path="/register" element={<Register />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/confirm" element={<Welcome/>} />
              {/* <Route path="/gifts" element={<UserGifts />} />
              <Route path="/reserved" element={<ReservedGifts />} />
              <Route path="/users" element={<AllUsers />} />
              <Route path="/addgift" element={<AddGift />} />
              <Route path="/contribute/:id" element={<Contribute />} /> */}
              <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
          </AuthState.Provider>
        </main>
      </div>
    </div>
  );
}

export default App;
