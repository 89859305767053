import React from "react";
import logo from "../h2logo.png";
function Header() {
  return (
    <div className="header-wrapper">
      <img src={logo} alt="Logo" />
      <h1>H2 SX SE 2024</h1>
    </div>
  );
}

export default Header;
