import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";

function Register() {

  const [userName, setUserName] = useState('');
  // const [userLastName, setUserLastName] = useState('');
  // const [userLoginName, setUserLoginName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPasswordCheck, setUserPasswordCheck] = useState('');
  const [loading, setLoading] = useState();
  const [gotResponse, setGotResponse] = useState();

  const handleChangeName = (e) => {
    setUserName(e.target.value)
  }

  // const handleChangeLastName = (e) => {
  //   setUserLastName(e.target.value)
  // }

  // const handleChangeLoginName = (e) => {
  //   setUserLoginName(e.target.value)
  // }

  const handleChangeEmail = (e) => {
    setUserEmail(e.target.value)
  }

  const handleChangePassword = (e) => {
    setUserPassword(e.target.value)
  }

  const handleChangePasswordCheck = (e) => {
    setUserPasswordCheck(e.target.value)
  }

  const navigate = useNavigate();

  console.log(userPassword, userPasswordCheck, gotResponse)

  function handleSubmit(event) {
    event.preventDefault();
    const newUser = {
      name: userName,
      email: userEmail,
      password: userPassword,
    };

    if (
      !userName ||
      // !userLastName ||
      // !userLoginName ||
      !userEmail ||
      !userPassword ||
      !userPasswordCheck
    ) {
      alert("Fill in all fields");
      return;
    } else if (
      userPassword !== userPasswordCheck
    ) {
      alert("Passwords do not match, please try again.");
      return;
    } else {
      setLoading(true);
      AuthService.signUp(newUser).then(function (response) {
        setLoading(false)
        return response.json();
      }).then((data)=>{
        setGotResponse(data.acknowledged)
      })
    }
    document.getElementById("registration-form").reset();
    // navigate("/login", { replace: true }
    
  }
  useEffect(()=>{
    if(gotResponse){
// alert('user registered'),
navigate("/login", { replace: true })
    }else{<div>Something went wrong, please try again.</div>}
  },[gotResponse, navigate])

  const checkIfSuccess = loading ? (
    <div>sending data....</div>
  ) : (<div></div>);

  return (
    <>
    <div>{checkIfSuccess}</div>
    <div className="register-wrapper">
      <div className="new-user-registration-title">New user registration:</div>
      <div className="message-text"></div>
      <div className="add-user">
        <form id="registration-form">
          <label htmlFor="user-name">
            First name<span className="red">*</span>
          </label>
          <input
            value={userName}
            type="text"
            id="user-name"
            name="user-name"
            onChange={handleChangeName}
          />

          {/* <label htmlFor="user-last-name">
            Last name<span className="red">*</span>
          </label>
          <input
            value={userLastName}
            type="text"
            id="user-last-name"
            name="user-last-name"
            onChange={handleChangeLastName}
          />

          <label htmlFor="user-login-name">
            Login name<span className="red">*</span>
          </label>
          <input
            value={userLoginName}
            type="text"
            id="user-login-name"
            name="user-login-name"
            onChange={handleChangeLoginName}
          /> */}

          <label htmlFor="user-email">
            Email<span className="red">*</span>
          </label>
          <input
            value={userEmail}
            type="email"
            id="user-email"
            name="user-email"
            onChange={handleChangeEmail}
            formNoValidate
          />

          <label htmlFor="user-password">
            Password<span className="red">*</span>
          </label>
          <input
            value={userPassword}
            type="password"
            id="user-password"
            name="user-password"
            onChange={handleChangePassword}
          />
          <label htmlFor="user-password-check">
            Repeat password<span className="red">*</span>
          </label>
          <input
            value={userPasswordCheck}
            type="password"
            id="user-password-check"
            name="user-password-check"
            onChange={handleChangePasswordCheck}
          />
          <div className="required">* required</div>
          <button
            className="form-submit-btn"
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
    </>
  );
}

export default Register;
