const AUTH_URL_LOCAL = "http://localhost:48878/h2nodeapp/v1/auth/";
const SAVES_URL_LOCAL = "http://localhost:48878/h2nodeapp/v1/h2/";
const AUTH_URL_REMOTE = "https://h2.baltuska.lt/h2nodeapp/v1/auth/";
const SAVES_URL_REMOTE = "https://h2.baltuska.lt/h2nodeapp/v1/h2/";

const siteLocalization = "global";

if (siteLocalization === "local") {
  var AUTH_URL = AUTH_URL_LOCAL;

  var SAVES_URL = SAVES_URL_LOCAL;
} else {
  AUTH_URL = AUTH_URL_REMOTE;
  SAVES_URL = SAVES_URL_REMOTE;
}
export { AUTH_URL, SAVES_URL };

export function openUrl (url) {
    window.open(url, "_blank", "noopener,noreferrer");
  };